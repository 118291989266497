<router>
{
    name: 'Sales Tool Version Manager',
}
</router>
<template lang="pug">
.versionManager
    v-container
        v-row.ml-1
            v-col(cols='6')
                v-card(light)
                    v-card-title.brand.white--text.py-2 Current Release Versions
                        v-spacer
                        v-btn(title='Refresh List', icon, dense, color='white', @click="refreshCurrentVersions")
                            v-icon refresh

                    v-card-text
                        v-row
                            //- Table
                            v-col(cols='12')
                                v-simple-table(dense)
                                    template(v-slot:default)
                                        thead
                                            tr
                                                th Release Channel
                                                th Current Download
                                                th Download
                                        tbody
                                            tr
                                                td
                                                    span Latest
                                                td
                                                    span(v-if='currentLatest') {{currentLatest}}
                                                    span(v-else) No "Latest" build found
                                                td
                                                    v-btn(title='Download Latest Build', :disabled='!currentLatest', download, icon, dense, color='green', @click="downloadFile(currentLatestURL)")
                                                        v-icon download
                                            tr
                                                td
                                                    span Beta
                                                td
                                                    span(v-if='currentBeta') {{currentBeta}}
                                                    span(v-else) No "Beta" build found
                                                td
                                                    v-btn(title='Download Current Beta Build',:disabled='!currentBeta', download, icon, dense, color='green', @click="downloadFile(currentBetaURL)")
                                                        v-icon download
                                            tr
                                                td
                                                    span Alpha
                                                td
                                                    span(v-if='currentAlpha') {{currentAlpha}}
                                                    span(v-else) No "Alpha" build found
                                                td
                                                    v-btn(title='Download Current Alpha Build',:disabled='!currentAlpha', download, icon, dense, color='green', @click="downloadFile(currentAlphaURL)")
                                                        v-icon download
                    v-card-actions
                        v-spacer
                        v-btn.white--text(@click='showNewBuild', color='brand') New Build

    v-dialog(v-model='showNewBuildDialog', max-width='50vw')
        v-card(light)
            v-card-title.brand.white--text.py-2 Build New SAT Release
            v-card-text
                v-form(ref='form', v-model='validBuild')

                    v-row.mt-2
                        span.ml-3.text-h6.black--text Build Info
                    v-row.mt-2
                        v-col(cols='4')
                            v-text-field(v-model='newBuildObj.version', label='Version', color='brand', outlined, :rules='versionValidation')
                        v-col(cols='4')
                            v-select(v-model='newBuildObj.channel', label='Release Channel', color='brand', outlined, :items='channelOptions', item-text='text', item-value='value')
                        v-col(cols='4')
                            v-autocomplete(label='Branch to Build', v-model='newBuildObj.branchToBuild', :items='satBranches', color='brand', outlined)

                    v-divider

                    v-row.mt-2
                        span.ml-3.text-h6.black--text Changelog
                    v-row.mt-2
                        span.ml-3 Enter changes one at a time, pressing enter between items.
                    v-row.mt-2
                        v-col(cols='4')
                            v-textarea(placeholder='Fix 1\nFix 2\nFix 3', label='Fixes', outlined, color='brand', no-resize, rows='7', v-model='newBuildObj.changelog.fixes')
                        v-col(cols='4')
                            v-textarea(placeholder='Added 1\nAdded 2\nAdded 3', label='Adds', outlined, color='brand', no-resize, rows='7', v-model='newBuildObj.changelog.adds')
                        v-col(cols='4')
                            v-textarea(placeholder='Removed 1\nRemoved 2\nRemoved 3', label='Removed', outlined, color='brand', no-resize, rows='7', v-model='newBuildObj.changelog.removes')

                    v-divider

                    v-row.mt-2
                        span.ml-3.text-h6.black--text Options
                    v-row.mt-2
                        v-col(cols='3')
                            v-checkbox(label='Publish to GitHub', v-model='newBuildObj.publishToGithub', color='brand', title='Creates new release branch and pushes to GitHub', hide-details)
                        v-col(cols='3')
                            v-checkbox(label='Release to Users', v-model='newBuildObj.setAsLatest', color='brand', title='Makes release available to all users in selected release channel', hide-details)
            v-card-actions
                v-spacer
                v-btn(text, @click='cancelNewBuild') Cancel
                v-btn.white--text(@click='finalCheck', :disabled='!validBuild', color='green') Build

            v-overlay(absolute, :value='busy')
                .text-h6 Please Wait...
                v-progress-linear(indeterminate)

        ConfirmDialog(:show="showConfirmDialog", confirmTitle='Build SAT Release', :confirmText="confirmText", @cancel="showConfirmDialog=false", @confirm="initiateBuild", confirmButtonText='Build', :width="400" )
    v-snackbar(v-model='snackbar', dark, :timeout='snackbarTimeout') {{ message }}
</template>

<script>
import _ from 'lodash';
import ConfirmDialog from "@/components/utils/ConfirmDialog";

export default {
    components: {
        ConfirmDialog,
    },
    data (){
        return {
            dti,
            accessKey: 'satversions',
            busy: false,
            download: [],
            fileVersion: '',
            fileURL: '',
            currentLatest: '',
            currentLatestURL: '',
            currentBeta: '',
            currentBetaURL: '',
            currentAlpha: '',
            currentAlphaURL: '',

            channelOptions: [
                {
                    text: 'Alpha',
                    value: 'alpha'
                },
                {
                    text: 'Beta',
                    value: 'beta'
                },
                {
                    text: 'Latest',
                    value: ''
                }
            ],

            newBuildObj: {
                version: '',
                channel: '',
                changelog: {
                    adds: '',
                    fixes: '',
                    removes: '',
                },
                publishToGithub: true,
                setAsLatest: true,
                branch: '',
                branchToBuild: 'develop',
            },
            showNewBuildDialog: false,
            satBranches: [],

            validBuild: false,
            nonEmptyRule: [
                v => !!v || "Build version is required",
            ],
            versionValidation: [
                v => !!v || "Build version is required",
                v => ( !v.includes(' ')) || "Version cannot contain spaces",
                v => ( v != ' ') || "Build version is required",
                v => ( v.length > 0) || "Build version cannot be empty",
                v => ( v.length <= 100 ) || "Build version should be less than 10 characters",
                v => ( v.length >= 5 ) || "Build version should follow the format X.X.X"
            ],
            showConfirmDialog: false,
            confirmText: '',
            snackbar: false,
            message: '',
            snackbarTimeout: 5000,
        };
    },
    computed: {
    },
    methods: {
        refreshCurrentVersions () {
            //Gets the version in the yaml file passed
            let getVersion  = (channel) => {
                this.axios
                    .get(`/satapi/updates/${channel}.yml`)
                    .then((response) => {
                        let data = response.data;
                        let re = new RegExp('url: (Sales-Automation-Tool-Setup-.*?.exe)');
                        let results = data.match(re);
                        const current = results[1];

                        switch(channel) {
                        case 'latest':
                            this.currentLatest = current;
                            this.currentLatestURL = (`${this.axios.defaults.baseURL}satapi/updates/${current}`);
                            break;
                        case 'beta':
                            this.currentBeta = current;
                            this.currentBetaURL = (`${this.axios.defaults.baseURL}satapi/updates/${current}`);
                            break;
                        case 'alpha':
                            this.currentAlpha = current;
                            this.currentAlphaURL = (`${this.axios.defaults.baseURL}satapi/updates/${current}`);
                            break;
                        default:
                            console.log(`Release channel "${channel}" not found.`);
                            break;
                        }
                    });
            };

            getVersion('alpha');
            getVersion('beta');
            getVersion('latest');
        },

        async refreshSATBranches () {
            let result = await this.sendCommand({action: 'getAllSATBranches'});
            this.satBranches = result;
        },

        showNewBuild () {
            this.showNewBuildDialog = true;
        },

        finalCheck () {
            const adds = this.newBuildObj.changelog.adds;
            const fixes = this.newBuildObj.changelog.fixes;
            const removes = this.newBuildObj.changelog.removes;

            //Check version string for spaces (breaks git commands)
            let vString = this.newBuildObj.version;
            vString = vString.trim();
            vString = vString.replaceAll(' ', '-');
            this.newBuildObj.version = vString;


            if(adds.length == 0 && fixes.length == 0 && removes.length == 0) {
                this.confirmText = 'No changelog items have been added. Continue with build?';
            }
            else {
                this.confirmText = `Build SAT release version "${this.newBuildObj.version}"?`;
            }

            this.showConfirmDialog = true;
        },

        async initiateBuild () {
            this.snackbarTimeout = 2000;
            this.message = 'Preparing to build. Please wait for process to start.';
            this.showConfirmDialog = false;
            this.showNewBuildDialog = false;

            const buildData = _.cloneDeep(this.newBuildObj);

            let building = await this.sendCommand({
                action: 'startSATBuild',
                parameters: buildData
            });

            this.snackbarTimeout = 5000;

            this.refreshCurrentVersions();

            if(building) {
                this.message = `SAT v${this.newBuildObj.version} build started.\nPlease allow up to 10 minutes for build to complete`;
                this.cancelNewBuild();
            }
            else {
                this.message = 'There was an issue starting this build. Please check server logs';
            }

        },

        cancelNewBuild () {
            this.showNewBuildDialog = false;
            this.newBuildObj = {
                version: '',
                channel: '',
                changelog: {
                    adds: '',
                    fixes: '',
                    removes: '',
                },
                publishToGithub: true,
                setAsLatest: true,
                branch: '',
                branchToBuild: 'develop',
            };
            this.refreshCurrentVersions();
        },

        downloadFile (url) {
            window.open(url);
        },

    },
    watch: {
        message (val) {
            if (val !== '') {
                this.snackbar = true;
                setTimeout(() => {
                    this.message = '';
                }, this.snackbarTimeout);
            }
        },
    },
    mounted (){
        window.satVersions = this;
        this.refreshCurrentVersions();
        this.refreshSATBranches();
    }
};
</script>
